export default () => {
   $(function () {
      var bsDefaults = {
         offset: false,
         overlay: true,
         width: '330px'
      },
      bsMain = $('.bs-offset-main'),
      bsOverlay = $('.bs-canvas-overlay');

      $('[data-toggle="canvas"][aria-expanded="false"]').on('click', function() {
      var canvas = $(this).data('target'),
         opts = $.extend({}, bsDefaults, $(canvas).data()),
         prop = $(canvas).hasClass('bs-canvas-right') ? 'margin-right' : 'margin-left';

      if (opts.width === '100%')
         opts.offset = false;

      $(canvas).css('width', opts.width);
      if (opts.offset && bsMain.length)
         bsMain.css(prop, opts.width);

      $(canvas + ' .bs-canvas-close').attr('aria-expanded', "true");
      $('[data-toggle="canvas"][data-target="' + canvas + '"]').attr('aria-expanded', "true");
      if (opts.overlay && bsOverlay.length)
         bsOverlay.addClass('show');
      return false;
      });

      $('.bs-canvas-close, .bs-canvas-overlay').on('click', function() {
      var canvas, aria;
      if ($(this).hasClass('bs-canvas-close')) {
         canvas = $(this).closest('.bs-canvas');
         aria = $(this).add($('[data-toggle="canvas"][data-target="#' + canvas.attr('id') + '"]'));
         if (bsMain.length)
            bsMain.css(($(canvas).hasClass('bs-canvas-right') ? 'margin-right' : 'margin-left'), '');
      } else {
         canvas = $('.bs-canvas');
         aria = $('.bs-canvas-close, [data-toggle="canvas"]');
         if (bsMain.length)
            bsMain.css({
               'margin-left': '',
               'margin-right': ''
            });
      }
      canvas.css('width', '');
      aria.attr('aria-expanded', "false");
      if (bsOverlay.length)
         bsOverlay.removeClass('show');
      return false;
      });
   });


   // Link to Tabbed Content
   /*$('.tab-anchor').on('click', function(e){
      let day = $(this).data('day');
      $tab = $('#' + day);
      if ($tab.hasClass('active')) {
          return;
      }
      switch (day) {
          case 'day-one':
              break;
          case 'day-two':
              $('#day-one').removeClass('active');
              $('#day-two').addClass('active');
              $('#day-three').removeClass('active');
              $('#day01').removeClass('active show');
              $('#day02').addClass('active show');
              $('#day03').removeClass('active show');
              break;
          case 'day-three':
            $('#day-one').removeClass('active');
            $('#day-two').removeClass('active');
            $('#day-three').addClass('active');
            $('#day01').removeClass('active show');
            $('#day02').removeClass('active show');
            $('#day03').addClass('active show');
              break;
          default:
              break;
      }
   });

   $(function() {
      $("a[data-tab-destination]").on('click', function(e) {
          e.preventDefault();
          // Works
          var tab = $(this).attr('data-tab-destination');

          $("#" + tab).click();

          // Doesn't work
          var hashtag = $(this.hash);

          var target = hashtag.length ? hashtag : $('[name=' + this.hash.slice(1) + ']');

          if (target.length) {
              $(".tab-pane").removeClass("fade").addClass("active");
              $('html, body').animate({scrollTop: target.offset().top}, 1000);
              return false;
          }
      });
   });*/



   // Back to top of page
   $(document).ready(function(){
      $(window).scroll(function(){
          if ($(this).scrollTop() > 100) {
              $('#btn-back-to-top').fadeIn();
          } else {
              $('#btn-back-to-top').fadeOut();
          }
      });
      $('#btn-back-to-top').click(function(){
          $("html, body").animate({ scrollTop: 0 }, 100);
          return false;
      });
   });

   //Pagination
   $(function () {
      $.fn.customPaginate = function(options) {
         var paginationContainer = this,
            itemsToPaginate,
            numberOfPaginationLinks,
            unorderList,
         defaults = {
            itemsPerPage: 5
         },
         settings = {
         };
         $.extend(settings,defaults,options);
         var itemsPerPage = settings.itemsPerPage;
         itemsToPaginate = $(settings.itemsToPaginate); //.post
         numberOfPaginationLinks = Math.ceil((itemsToPaginate.length / itemsPerPage));
         $("<ul></ul>").prependTo(paginationContainer);
         unorderList = paginationContainer.find("ul");
         for(var i = 0; i < numberOfPaginationLinks; i++) {
            unorderList.append("<li>" + (i + 1) + "</li>");
         }
         itemsToPaginate.filter(":gt(" + (itemsPerPage - 1) + ")").hide();
         unorderList.children("li").eq(0).addClass("active");
         unorderList.children("li").on("click",function(){
            var linkNumber = $(this).text(),
                  itemsToHide = itemsToPaginate.filter(":lt(" + ((linkNumber - 1) * itemsPerPage) + ")");
                  $.merge(itemsToHide,itemsToPaginate.filter(":gt(" + ((linkNumber * itemsPerPage) - 1) + ")"));
                  itemsToHide.hide();
                  linkNumber = $(this);
                  linkNumber.siblings().removeClass("active");
                  linkNumber.addClass("active");
            var itemsToShow = itemsToPaginate.not(itemsToHide);
            itemsToShow.show();
         });
      };
   });

   // init pagination
   $(function () {
      $(document).ready(function(){
         $(".pagination").customPaginate({
            itemsToPaginate: ".post",
            itemsPerPage: 10
         });
      });
   });
   $(document).on('click','.pagination', function() {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
   });
}

//-------------------------------------------------------------------ew2021id
/*
 * Plugin name - rTabs
*/
(function($){
   $.fn.rTabs = function(options){
       //默认值
       var defaultVal = {
           defaultShow:0,   //默认显示第几个
           prev:'#prev',
           next:'#next',
           btnClass:'.j-tab-nav',  /*按钮的父级Class*/
           conClass:'.j-tab-con',  /*内容的父级Class*/
           bind:'hover',   /*事件参数 click,hover*/
           animation:'0',  /*动画方向 left,up,fadein,0 为无动画*/
           speed:300,  /*动画运动速度*/
           delay:200,  /*Tab延迟速度*/
           auto:true,  /*是否开启自动运行 true,false*/
           autoSpeed:3000  /*自动运行速度*/
       };

       //全局变量
       var obj = $.extend(defaultVal, options),
           evt = obj.bind,
           btn = $(this).find(obj.btnClass),
           con = $(this).find(obj.conClass),
           prev = $(obj.prev),
           next = $(obj.next),
           anim = obj.animation,
           conWidth = con.width(),
           conHeight = con.height(),
           len = con.children().length,
           sw = len * conWidth,
           sh = len * conHeight,
           i = obj.defaultShow,
           len,t,timer;

       // 根据锚点显示内容
       if(obj.defaultShow==true){
           var hash = window.location.hash.slice(1);
           btn.children().each(function() {
               if(hash==$(this).attr('show-index')){
                   i = $(this).index();
                   return false;
               }
               i = 0;
           });
       }

       return this.each(function(){
           //判断动画方向
           function judgeAnim(){
               var w = i * conWidth,
                   h = i * conHeight;
               btn.children().removeClass('current').eq(i).addClass('current');
               switch(anim){
                   case '0':
                   con.children().hide().eq(i).show();
                   break;
                   case 'left':
                   con.css({position:'absolute',width:sw}).children().css({float:'left',display:'block'}).end().stop().animate({left:-w},obj.speed);
                   break;
                   case 'up':
                   con.css({position:'absolute',height:sh}).children().css({display:'block'}).end().stop().animate({top:-h},obj.speed);
                   break;
                   case 'fadein':
                   con.children().hide().eq(i).fadeIn();
                   break;
               }
           }
           judgeAnim();

           prev.click(function(){
               i--;
               if(i<0){
                   i=0;
                   return true;
               }
               judgeAnim();
           });

           next.click(function(){
               i++;
               if(i>=len){
                   i=len-1;
                   return true;
               }
               judgeAnim();
           });

           //判断事件类型
           if(evt == "hover"){
               btn.children().hover(function(){
                   var j = $(this).index();
                   function s(){
                       i = j;
                       judgeAnim();
                   }
                   timer=setTimeout(s,obj.delay);
               }, function(){
                   clearTimeout(timer);
               })
           }else{
               btn.children().bind(evt,function(){
                   i = $(this).index();
                   judgeAnim();
               })
           }

           //自动运行
           function startRun(){
               t = setInterval(function(){
                   i++;
                   if(i>=len){
                       switch(anim){
                           case 'left':
                           con.stop().css({left:conWidth});
                           break;
                           case 'up':
                           con.stop().css({top:conHeight});
                       }
                       i=0;
                   }
                   judgeAnim();
               },obj.autoSpeed)
           }

           //如果自动运行开启，调用自动运行函数
           if(obj.auto){
               $(this).hover(function(){
                   clearInterval(t);
               },function(){
                   startRun();
               })
               startRun();
           }
       })
   }
})(jQuery);

$(".c_content_tab").rTabs({
	bind: 'click',
	animation: 'fadein',
	delay: 800,
	auto: false
});	


//-------------------------------------------------------------------ew2023ase
const modalBtns = document.querySelectorAll(".modalOpen");
modalBtns.forEach(function (btn) {
  btn.onclick = function () {
    var modal = btn.getAttribute('data-modal');
    document.getElementById(modal).style.display = "block";
  };
});
const closeBtns = document.querySelectorAll(".modalClose");
closeBtns.forEach(function (btn) {
  btn.onclick = function () {
    var modal = btn.closest('.modal');
    modal.style.display = "none";
  };
});

window.onclick = function (event) {
  if (event.target.className === "modal") {
    event.target.style.display = "none";
  }
};



//-------------------------------------------------------------------ew2025ph
$(function () {
    // ページ内リンククリック後にハンバーガーメニューを閉じる
    $(".navcheck_box").click(function () {
        $(this).toggleClass("active");
        $("#menu").toggleClass("active");
    });
    
    $(".nav-link").click(function () {
        $("#navcheck").prop("checked", false);
        $(".navcheck_box").toggleClass("active");
        $("#menu").toggleClass("active");
    });
    // function
    });




    


  // COP28
  $(document).ready(function(){ 
    $(window).scroll(function(){ 
        if ($(this).scrollTop() > 100) { 
            $('#btn-float_cop28').fadeIn(); 
        } else { 
            $('#btn-float_cop28').fadeOut(); 
        } 
    }); 
 });

   // COP29
   $(document).ready(function(){ 
    $(window).scroll(function(){ 
        if ($(this).scrollTop() > 100) { 
            $('#btn-float_cop29').fadeIn(); 
        } else { 
            $('#btn-float_cop29').fadeOut(); 
        } 
    }); 
 });



 // 個人会員申込
 $("#how_know").change(function() {
 var extraction_val = $("#how_know").val();
 if(extraction_val == "その他") {
   $('#other_input').attr({
     type: 'text'
   })
 }
 else if (extraction_val != "その他") {   
    $('#other_input').attr({
        type: 'hidden'
      })
 }
 });
